import _objectSpread from "/Users/jason/dev/bidatc/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/Users/jason/dev/bidatc/dashboard/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _TimeSelect2 from "element-ui/lib/theme-chalk/time-select.css";
import "element-ui/lib/theme-chalk/base.css";
import _TimeSelect from "element-ui/lib/time-select";
import _DatePicker2 from "element-ui/lib/theme-chalk/date-picker.css";
import "element-ui/lib/theme-chalk/base.css";
import _DatePicker from "element-ui/lib/date-picker";
import _Dialog2 from "element-ui/lib/theme-chalk/dialog.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dialog from "element-ui/lib/dialog";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import moment from 'moment-timezone';
var RoundRepository = RepositoryFactory.get('round');
export default {
  name: 'bid-window-config',
  components: (_components = {}, _defineProperty(_components, _Dialog.name, _Dialog), _defineProperty(_components, _DatePicker.name, _DatePicker), _defineProperty(_components, _TimeSelect.name, _TimeSelect), _components),
  props: {
    round: Object
  },
  data: function data() {
    return {
      visible: false,
      config: {
        start: null,
        duration: 30
      }
    };
  },
  methods: {
    save: function save() {
      var _this = this;

      // Convert to the facility time zone when submitting the start time
      var facilityOffset = moment.tz.zone(this.facility.timezone).utcOffset(this.config.start);
      var modifyOffset = facilityOffset - this.utcOffset;
      var startMoment = moment.utc(this.config.start);
      var data = {
        start: this.config.start,
        // startMoment.add(modifyOffset, 'minutes'),
        duration: this.config.duration
      }; // Create the windows

      RoundRepository.createBidWindows(this.round.id, data).then(function (r) {
        _this.visible = false; // Force update of this round

        _this.$emit('refresh', r.data);
      });
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    facility: 'facility/facility'
  })), {}, {
    dialogWidth: function dialogWidth() {
      return this.screenSize !== 'lg' && this.screenSize !== 'xl' ? 80 : 40;
    },
    hours: function hours() {
      // Convert bid_hours to local time
      return this.utcTimes(JSON.parse(JSON.stringify(this.facility.bid_hours)), this.facility.timezone, ['open', 'close'], true);
    },
    facilityTimezone: function facilityTimezone() {
      return moment.tz(this.facility.timezone).zoneAbbr();
    }
  })
};